import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { ForecastModule } from './pages/forecast/forecast.module';
import { ExtremesModule } from './pages/extremes/extremes.module';
import { WebcamModule } from './pages/webcam/webcam.module';
import { GraphsModule } from './pages/graphs/graphs.module';
import { ReportsModule } from './pages/reports/reports.module';
import { WeatherStationsModule } from './pages/weather-stations/weather-stations.module';
import { WeatherForecastModule } from './pages/weather-forecast/weather-forecast.module';
import { WeatherMapsModule } from './pages/weather-maps/weather-maps.module';
import { ContactModule } from './pages/contact/contact.module';
import { AboutModule } from './pages/about/about.module';
import { PrivacyPolicyModule } from './pages/privacy-policy/privacy-policy.module';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./pages/dashboard/dashboard.module').then(
                m => m.DashboardModule
            ),
        data: {
            title: 'Drentheweer - weerstation Gasselternijveenschemond',
            description:
                'Het weer in Gasselternijveenschemond, Drenthe. Actuele en historische weergegevens van het weerstation, live webcam, verwachtingen, grafieken en meer.',
        },
    },
    {
        path: 'verwachting',
        loadChildren: () =>
            import('./pages/forecast/forecast.module').then(
                m => m.ForecastModule
            ),
        data: {
            title: 'Verwachting voor Gasselternijveenschemond',
            description:
                'De meerdaagse weersverwachting voor Gasselternijveenschemond. Dagelijks meerdere updates.',
        },
    },
    {
        path: 'extremen',
        loadChildren: () =>
            import('./pages/extremes/extremes.module').then(
                m => m.ExtremesModule
            ),
        data: {
            title: 'Extremen',
            description:
                'Bekijk de extremen van vandaag, gisteren, deze maand en het huidige jaar, gemeten door het weerstation.',
        },
    },
    {
        path: 'webcam',
        loadChildren: () =>
            import('./pages/webcam/webcam.module').then(m => m.WebcamModule),
        data: {
            title: 'Webcam',
            description:
                'Bekijk het actuele weerbeeld in Gasselternijveenschemond via de webcam. Zie het weersverloop met behulp van timelapses.',
        },
    },
    {
        path: 'grafieken',
        loadChildren: () =>
            import('./pages/graphs/graphs.module').then(m => m.GraphsModule),
        data: {
            title: 'Grafieken',
            description:
                'Grafieken van het weer in Gasselternijveenschemond. Temperatuur, luchtvochtigheid, luchtdruk, wind en neerslag.',
        },
    },
    {
        path: 'weerrapporten',
        loadChildren: () =>
            import('./pages/reports/reports.module').then(m => m.ReportsModule),
        data: {
            title: 'Weerrapporten',
            description:
                'Weerrapporten met gegevens per dag van het weer in Gasselternijveenschemond.',
        },
    },
    {
        path: 'weerstations-drenthe',
        loadChildren: () =>
            import('./pages/weather-stations/weather-stations.module').then(
                m => m.WeatherStationsModule
            ),
        data: {
            title: 'Weerstations in Drenthe',
            description:
                'De weerstations in Drenthe. Bekijk wat het weer is in andere plaatsen in Drenthe.',
        },
    },
    {
        path: 'weerbericht',
        loadChildren: () =>
            import('./pages/weather-forecast/weather-forecast.module').then(
                m => m.WeatherForecastModule
            ),
        data: {
            title: 'Weerbericht',
            description:
                'Lees het actuele weerbericht voor Nederland om op de hoogte te blijven van het weer.',
        },
    },
    {
        path: 'weerkaarten',
        loadChildren: () =>
            import('./pages/weather-maps/weather-maps.module').then(
                m => m.WeatherMapsModule
            ),
        data: {
            title: 'Weerkaarten',
            description:
                'Zie in een oogopslag wat het weer is in Drenthe met behulp van de weerkaarten.',
        },
    },
    {
        path: 'contact',
        loadChildren: () =>
            import('./pages/contact/contact.module').then(m => m.ContactModule),
        data: {
            title: 'Contact opnemen met Drentheweer',
            description:
                'Heb je een vraag of opmerking? Laat het Drentheweer dan weten via het contactformulier.',
        },
    },
    {
        path: 'over',
        loadChildren: () =>
            import('./pages/about/about.module').then(m => m.AboutModule),
        data: {
            title: 'Over Drentheweer',
            description:
                'Algemene informatie over Drentheweer en het weerstation in Gasselternijveenschemond.',
        },
    },
    {
        path: 'privacyverklaring',
        loadChildren: () =>
            import('./pages/privacy-policy/privacy-policy.module').then(
                m => m.PrivacyPolicyModule
            ),
        data: {
            title: 'Privacyverklaring',
            description:
                'Lees in de privacyverklaring hoe Drentheweer met uw privacy omgaat.',
        },
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
