import { NgtUniversalModule } from '@ng-toolkit/universal';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/extra/nl';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import {
    NgcCookieConsentModule,
    NgcCookieConsentConfig,
} from 'ngx-cookieconsent';

import { Angulartics2Module } from 'angulartics2';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

let releaseStage = 'production';

if (!environment.production) {
    releaseStage = 'development';
}

Bugsnag.start({
    apiKey: environment.bugsnagApiKey,
    enabledReleaseStages: ['production'],
    releaseStage,
});

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.domain,
    },
    position: 'bottom',
    theme: 'classic',
    palette: {
        popup: {
            background: '#1868c9',
            text: '#ffffff',
            link: '#ffffff',
        },
        button: {
            background: '#1868c9',
            text: '#ffffff',
            border: '#ffffff',
        },
    },
    type: 'info',
    content: {
        message:
            'Drentheweer maakt gebruik van cookies om de gebruikservaring te optimaliseren.',
        allow: 'Akkoord',
        dismiss: 'Sluiten',
        deny: 'Niet toestaan',
        link: 'Meer lezen',
        href: '/privacyverklaring',
    },
};

registerLocaleData(localeNl, 'nl-NL', localeNlExtra);

// Add icons to library
library.add(faTwitter, faYoutube);

export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}

@NgModule({
    declarations: [AppComponent, NotFoundComponent],
    imports: [
        CommonModule,
        NgtUniversalModule,
        AppRoutingModule,
        HttpClientModule,
        FontAwesomeModule,
        Angulartics2Module.forRoot(),
        NgcCookieConsentModule.forRoot(cookieConfig),
    ],
    providers: [
        Title,
        {
            provide: ErrorHandler,
            useFactory: errorHandlerFactory,
        },
        {
            provide: LOCALE_ID,
            useValue: 'nl-NL',
        },
    ],
})
export class AppModule {}
