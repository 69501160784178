import { AppBrowserModule } from '.././src/app/app.browser.module';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppBrowserModule)
    .then(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/ngsw-worker.js');
        }
    })
    .catch(err => console.log(err));
