export class Breakpoints {
    small: number;
    medium: number;
    large: number;
    xlarge: number;
    xxlarge: number;

    constructor() {
        this.small = 0;
        this.medium = 640;
        this.large = 1024;
        this.xlarge = 1200;
        this.xxlarge = 1440;
    }
}
