import { Injectable } from '@angular/core';
import { connect, MqttClient } from 'mqtt';
import { WeewxMqttData } from '../objects/weewx-mqtt-data';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MqttService {
    public connectionOpen: boolean;
    public connectionOpenAnnounced$: any;
    public dataAnnounced$: any;
    private connectionOpenAnnouncedSource: Subject<boolean>;
    private dataAnnouncedSource: Subject<WeewxMqttData>;
    private client: MqttClient;

    private readonly topicBase = 'drentheweer/sensors/';

    constructor() {
        this.connectionOpenAnnouncedSource = new Subject<boolean>();
        this.connectionOpenAnnounced$ = this.connectionOpenAnnouncedSource.asObservable();
        this.dataAnnouncedSource = new Subject<WeewxMqttData>();
        this.dataAnnounced$ = this.dataAnnouncedSource.asObservable();
    }

    create() {
        this.client = connect(
            environment.mqttUrl,
            {
                port: environment.mqttPort,
                protocol: 'wss',
            }
        );

        this.client.on('connect', () => {
            this.connectionOpen = true;
            this.connectionOpenAnnouncedSource.next(this.connectionOpen);

            this.subscribeToTopic('loop');
        });
        // TODO retrieve retained data
        this.client.on('message', (topic: string, message: Uint8Array) => {
            const key = topic.split('/').pop();

            const textDecoder = new TextDecoder('utf-8');
            this.dataAnnouncedSource.next(
                JSON.parse(textDecoder.decode(message))
            );
        });

        this.client.on('close', () => {
            this.connectionOpen = false;
            this.connectionOpenAnnouncedSource.next(this.connectionOpen);
        });
    }

    private subscribeToTopic(topic: string) {
        this.client.subscribe(
            `${this.topicBase}${topic}`,
            {
                qos: 0,
            },
            (error: Error, granted: any) => {
                if (error === null) {
                    return;
                }

                this.connectionOpen = false;
                this.connectionOpenAnnouncedSource.next(this.connectionOpen);
            }
        );
    }

    close() {
        this.client.end();
        this.connectionOpen = false;
        this.connectionOpenAnnouncedSource.next(this.connectionOpen);
    }
}
