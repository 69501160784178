export const environment = {
    production: true,
    domain: "drentheweer.nl",
    apiUrl: "https://drentheweer.nl/api/",
    wsUrl: "wss://drentheweer.nl/wss",
    mqttUrl: "wss://rambit.nl",
    mqttPort: 9001,
    buienradarApiUrl: "https://api.buienradar.nl/data/public/2.0/jsonfeed",
    webcamLiveStreamUrl: "https://drentheweer.nl/webcam/live/stream",
    bugsnagApiKey: "d488cdd2714d6a4d42daa69c89a6c11a",
};
