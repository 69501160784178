<header class="header-wrapper box-shadow">
    <div class="grid-container">
        <div class="grid-x grid-margin-x">
            <div class="cell auto no-js">
                <div
                    class="title-bar"
                    data-responsive-toggle="responsive-menu"
                    data-hide-for="large"
                >
                    <div class="title-bar-left">
                        <div class="title-bar-title">
                            <a class="menu-image__link" routerLink="/">
                                <img
                                    src="/assets/img/logo.svg"
                                    alt="Drentheweer"
                                    class="title-bar__logo"
                                />
                            </a>
                        </div>
                    </div>
                    <div class="title-bar-right">
                        <button
                            class="menu-icon"
                            type="button"
                            (click)="toggleMenu()"
                            aria-label="Menu"
                        ></button>
                    </div>
                </div>

                <div
                    class="top-bar"
                    id="responsive-menu"
                    data-hide-for="large"
                    [style.display]="displayMenu"
                >
                    <div class="top-bar-left show-for-large">
                        <ul class="dropdown menu" data-dropdown-menu>
                            <li class="menu-image">
                                <a class="menu-image__link" routerLink="/">
                                    <img
                                        src="/assets/img/logo.svg"
                                        alt="Drentheweer"
                                        class="top-bar__logo"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <nav class="top-bar-right">
                        <ul
                            class="vertical large-horizontal menu"
                            data-responsive-menu="accordion large-dropdown"
                        >
                            <li
                                class="top-bar__menu-item"
                                routerLinkActive="top-bar__menu-item--active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                *ngFor="let menuItem of menuItems"
                            >
                                <a
                                    routerLink="{{ menuItem.url }}"
                                    class="top-bar__menu-link"
                                    (click)="toggleMenu()"
                                    >{{ menuItem.name }}</a
                                >
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</header>

<main>
    <router-outlet></router-outlet>
</main>

<footer>
    <div class="grid-container">
        <div class="grid-x grid-margin-x">
            <div class="cell large-auto">
                <div class="footer__heading">Drentheweer</div>
                <ul class="no-bullet">
                    <li *ngFor="let menuItem of menuItems">
                        <a routerLink="{{ menuItem.url }}"
                            >{{ menuItem.name }}</a
                        >
                    </li>
                </ul>
            </div>
            <div class="cell large-auto">
                <div class="footer__heading">Overig</div>
                <ul class="no-bullet">
                    <li>
                        <a routerLink="/weerstations-drenthe"
                            >Weerstations in Drenthe</a
                        >
                    </li>
                    <li>
                        <a routerLink="/weerbericht">Weerbericht</a>
                    </li>
                </ul>
            </div>
            <div class="cell large-auto">
                <div class="footer__heading">Informatie</div>
                <ul class="no-bullet">
                    <li>
                        <a routerLink="/over">Over Drentheweer</a>
                    </li>
                    <li>
                        <a routerLink="/contact">Contact</a>
                    </li>
                    <li>
                        <a routerLink="/privacyverklaring">Privacyverklaring</a>
                    </li>
                </ul>
            </div>
            <div class="cell large-auto">
                <div class="footer__heading">Volg Drentheweer</div>
                <ul class="no-bullet">
                    <li>
                        <a
                            href="https://twitter.com/drentheweer"
                            target="_blank"
                            rel="noopener"
                        >
                            <fa-icon
                                class="footer__icon"
                                [icon]="['fab', 'twitter']"
                                [fixedWidth]="true"
                                size="1x"
                            ></fa-icon>
                            <span>Twitter</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.youtube.com/channel/UCcQeRw2PfP-OCd3CTXjFWmw"
                            target="_blank"
                            rel="noopener"
                        >
                            <fa-icon
                                class="footer__icon"
                                [icon]="['fab', 'youtube']"
                                [fixedWidth]="true"
                                size="1x"
                            ></fa-icon>
                            <span>YouTube</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="grid-x grid-margin-x align-justify">
            <div class="cell small-12 medium-12 large-auto">
                <img
                    class="footer__logo"
                    src="/assets/img/logo-white.svg"
                    alt="Logo Drentheweer"
                />
            </div>
            <div class="cell small-12 medium-12 large-auto align-self-bottom">
                <div class="footer__copyright">
                    <small>
                        &copy; 2011-{{ date }} Drentheweer - weerstation
                        Gasselternijveenschemond | Gerealiseerd door
                        <a
                            href="https://rambit.nl"
                            target="_blank"
                            rel="noopener"
                            >RambIT</a
                        >
                    </small>
                </div>
            </div>
        </div>
    </div>
</footer>
