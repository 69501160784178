import { isPlatformBrowser } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';
import {
    Inject,
    Injector,
    PLATFORM_ID,
    Component,
    OnInit,
    OnDestroy,
    ElementRef,
    Renderer2,
} from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

import { WebSocketService } from './services/web-socket.service';
import { Breakpoints } from './objects/breakpoints';
import { MqttService } from './services/mqtt.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public isBrowser: boolean;
    public title: string;
    public date: number;
    public displayMenu: string;
    public menuItems: object[];
    private titles: object;
    private routerEventsSubscriber: any;
    private breakpoints: Breakpoints;
    private resizeHandler: any;
    private popupCloseSubscription: Subscription;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(WINDOW) private window: Window,
        private el: ElementRef,
        private titleService: Title,
        private metaService: Meta,
        private renderer: Renderer2,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private webSocketService: WebSocketService,
        private readonly mqttService: MqttService,
        private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private readonly injector: Injector
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.title = 'drentheweer';
        this.date = new Date().getFullYear();
        this.displayMenu = '';
        this.titles = {
            dashboard: 'Dashboard',
        };
        this.breakpoints = new Breakpoints();
        this.menuItems = [
            {
                name: 'Dashboard',
                url: '/',
            },
            {
                name: 'Verwachting',
                url: '/verwachting',
            },
            {
                name: 'Webcam',
                url: '/webcam',
            },
            {
                name: 'Extremen',
                url: '/extremen',
            },
            {
                name: 'Grafieken',
                url: '/grafieken',
            },
            {
                name: 'Weerrapporten',
                url: '/weerrapporten',
            },
        ];

        angulartics2GoogleTagManager.startTracking();
    }

    ngOnInit() {
        if (this.isBrowser) {
            this.webSocketService.create();
            this.mqttService.create();
            const ccService = this.injector.get(NgcCookieConsentService);
            ccService.init(ccService.getConfig());

            this.popupCloseSubscription = ccService.popupClose$.subscribe(
                () => {
                    // Fix for popup closing only after second click
                    ccService.close(false);
                }
            );

            let running = false;
            const throttle = (type, name) => {
                this.resizeHandler = () => {
                    if (running) {
                        return;
                    }

                    running = true;

                    requestAnimationFrame(() => {
                        this.window.dispatchEvent(new CustomEvent(name));
                        running = false;
                    });
                };
                this.window.addEventListener(type, this.resizeHandler);
            };

            throttle('resize', 'customResize');

            if (document.body.clientWidth < this.breakpoints.large) {
                this.displayMenu = 'none';
            }

            this.window.addEventListener(
                'customResize',
                this.handleResize.bind(this)
            );
        }

        this.routerEventsSubscriber = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute.firstChild.data)
            )
            .subscribe(data => {
                data.pipe(first()).subscribe(value => {
                    // Update title and description of page
                    if (value.title !== undefined) {
                        this.titleService.setTitle(value.title);
                    }

                    if (value.description !== undefined) {
                        this.metaService.updateTag({
                            name: 'description',
                            content: value.description,
                        });
                    }
                });
            });
    }

    ngOnDestroy() {
        if (this.isBrowser) {
            this.webSocketService.close();
            this.mqttService.close();
            this.window.removeEventListener('resize', this.resizeHandler);
            this.window.removeEventListener('customResize', this.handleResize);
        }
        this.routerEventsSubscriber.unsubscribe();
        this.popupCloseSubscription.unsubscribe();
    }

    handleResize() {
        if (document.body.clientWidth >= this.breakpoints.large) {
            this.displayMenu = '';
        }
    }

    toggleMenu() {
        if (this.isBrowser) {
            if (document.body.clientWidth < this.breakpoints.large) {
                if (this.displayMenu === '' || this.displayMenu === 'none') {
                    this.displayMenu = 'block';
                    this.renderer.setStyle(
                        document.body,
                        'overflow-y',
                        'hidden'
                    );
                } else {
                    this.displayMenu = 'none';
                    this.renderer.setStyle(document.body, 'overflow-y', '');
                }
            } else {
                // Ensure scrolling is allowed
                this.renderer.setStyle(document.body, 'overflow-y', '');
            }
        }
    }
}
