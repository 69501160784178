import { Component, OnInit, Inject, Optional } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
    public alternatives: object[];

    constructor(
        @Optional() @Inject(RESPONSE) private response: any,
        private metaService: Meta
    ) {
        this.alternatives = [
            {
                name: 'Dashboard',
                url: '/'
            },
            {
                name: 'Verwachting',
                url: '/verwachting'
            },
            {
                name: 'Webcam',
                url: '/webcam'
            },
            {
                name: 'Extremen',
                url: '/extremen'
            },
            {
                name: 'Grafieken',
                url: '/grafieken'
            },
            {
                name: 'Weerrapporten',
                url: '/weerrapporten'
            },
        ];
    }

    ngOnInit() {
        this.metaService.addTag({
            name: 'prerender-status-code',
            content: '404'
        });

        if (this.response) {
            this.response.statusCode = 404;
            this.response.statusMessage = '404 - Page not found';
        }
    }

}
