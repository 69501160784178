<div class="grid-container">
    <div class="grid-x grid-margin-x grid-margin-y align-center">
        <div class="cell large-6">
            <h1>Pagina niet gevonden</h1>

            <p>De opgevraagde pagina kan niet worden gevonden. Mogelijk ben je op zoek naar een van de onderstaande pagina's.</p>

            <div class="grid-x">
                <div class="cell large-12" *ngFor="let alternative of alternatives;">
                    <a routerLink="{{ alternative.url }}">{{ alternative.name }}</a>
                </div>
            </div>

            <div class="grid-x grid-margin-y">
                <div class="cell large-12">
                    <div class="error-code">
                        <small>Errorcode: 404</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
