import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { RealtimeData } from '../objects/realtime-data';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {
    public connectionOpen: boolean;
    public realtimeData: RealtimeData;
    public extremeData: any;
    public connectionOpenAnnounced$: any;
    public realtimeDataAnnounced$: any;
    public extremeDataAnnounced$: any;
    private connectionOpenAnnouncedSource: Subject<boolean>;
    private realtimeDataAnnouncedSource: Subject<object>;
    private extremeDataAnnouncedSource: Subject<object>;
    private ws: WebSocket;
    private reconnections: number;

    constructor() {
        this.connectionOpenAnnouncedSource = new Subject<boolean>();
        this.connectionOpenAnnounced$ = this.connectionOpenAnnouncedSource.asObservable();
        this.realtimeDataAnnouncedSource = new Subject<object>();
        this.realtimeDataAnnounced$ = this.realtimeDataAnnouncedSource.asObservable();
        this.extremeDataAnnouncedSource = new Subject<object>();
        this.extremeDataAnnounced$ = this.extremeDataAnnouncedSource.asObservable();

        this.reconnections = 0;
    }

    create() {
        this.ws = new WebSocket(environment.wsUrl);

        this.ws.onopen = (event) => {
            this.connectionOpen = true;
            this.connectionOpenAnnouncedSource.next(this.connectionOpen);
            this.reconnections = 0;
        };

        this.ws.onerror = (event) => {
            this.connectionOpen = false;
            this.connectionOpenAnnouncedSource.next(this.connectionOpen);
        };

        this.ws.onmessage = (event) => {
            const eventData = JSON.parse(event.data);
            if (eventData.realtime !== undefined) {
                this.realtimeData = eventData.realtime;
                this.realtimeDataAnnouncedSource.next(this.realtimeData);
            }
            if (eventData.extremes !== undefined) {
                this.extremeData = eventData.extremes;
                this.extremeDataAnnouncedSource.next(this.extremeData);
            }
        };

        this.ws.onclose = (event) => {
            this.connectionOpen = false;
            this.connectionOpenAnnouncedSource.next(this.connectionOpen);

            this.reconnect();
        };
    }

    close() {
        this.ws.close();
    }

    reconnect() {
        let timeout = 10000;

        if (this.reconnections < 10) {
            timeout = 5000;
        }

        setTimeout(() => {
            this.create();
        }, timeout);

        this.reconnections++;
    }
}
